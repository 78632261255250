/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dad9d9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #666;
}
.mat-select-panel {
  max-height: 80vh !important;
}
$toolbar-height: 3rem;
.spacer {
  height: $toolbar-height;
  width: 100%;
}
